import { cn } from '~/lib/utils';

export function ContentSection({
  id,
  children,
  className,
}: {
  id?: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <section id={id} className={cn('px-4 lg:px-0', className)}>
      {children}
    </section>
  );
}
