import { PlayIcon } from 'lucide-react';
import { ReactNode, useState } from 'react';
import { If } from '~/components/If';

export interface YouTubeInlinePlayerProps {
  embedUrl: string;
  previewImgUrl: string;
  children: ReactNode;
  onPlay?: () => void;
}

export function YouTubeInlinePlayer({ embedUrl, previewImgUrl, onPlay, children }: YouTubeInlinePlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const handleClick = () => {
    if (onPlay) {
      onPlay();
    }
    setIsPlaying(true);
  };

  return (
    <div className="relative h-full w-full">
      <If condition={isPlaying}>
        <iframe
          className="absolute h-full w-full"
          src={`${embedUrl}&autoplay=1&loop=1&autopause=0&muted=`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </If>
      <If condition={!isPlaying}>
        <img className="absolute h-full w-full rounded-lg object-cover" src={previewImgUrl} />
        <div
          className="absolute z-10 h-full w-full"
          style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%)' }}
        />
        <div className="absolute bottom-5 left-1/2 z-20 flex w-full -translate-x-1/2 flex-col items-center justify-center text-white">
          <div className="mb-2 cursor-pointer rounded-full bg-white p-4" onClick={handleClick}>
            <PlayIcon color="black" fill="black" />
          </div>
          {children}
        </div>
      </If>
    </div>
  );
}
