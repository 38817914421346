import asyLogoImgUrl from '~/assets/images/brand-logos/asy-logo.png';
import asyBrandBBgImgUrl from '~/assets/images/homepage/asy-brand-background.jpeg';
import { FacebookSvgWhite } from '~/components/social-svgs/facebook-svg-white';
import { InstagramSvgWhite } from '~/components/social-svgs/instagram-svg-white';
import { Button } from '~/components/ui/button';
import { Typography } from '~/components/ui/typography';

export function AsyBrandCard() {
  return (
    <div className="relative h-[50vh] shadow-2xl md:aspect-[16/7] md:h-auto md:w-4/5">
      <img className="absolute h-full w-full object-cover md:rounded-lg" src={asyBrandBBgImgUrl} />
      <div
        className="absolute z-10 h-full w-full md:rounded-lg"
        style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%)' }}
      />
      <div className="absolute left-0 top-0 z-20 max-w-40 bg-white p-4">
        <img src={asyLogoImgUrl} alt="Abhyas School of Yoga" />
      </div>
      <div className="relative z-20 flex h-full w-full flex-col items-center justify-end px-4 pb-8 text-center md:pb-14">
        <Typography variant="h2" className="text-white">
          Abhyas School of Yoga
        </Typography>
        <div className="flex flex-row items-center justify-center gap-2">
          <Typography variant="tagline" className="my-2 font-extralight text-white md:text-sm">
            The real essence of Yog
          </Typography>
          <a href="https://www.facebook.com/abhyasschoolofyoga/" target="_blank" rel="noreferrer">
            <FacebookSvgWhite />
          </a>
          <a href="https://www.instagram.com/abhyasschoolofyoga/" target="_blank" rel="noreferrer">
            <InstagramSvgWhite />
          </a>
        </div>
        <Typography className="text-white">
          A Yoga school which teaches participants how to lead a life imbued with the principles of Yog, as defined by
          the Ancient Rishi tradition.
        </Typography>
        <div className="flex items-center justify-center space-x-4">
          <a href="https://www.abhyasschoolofyoga.org/" target="_blank" rel="noreferrer">
            <Button className="mt-4">Learn More</Button>
          </a>
        </div>
      </div>
    </div>
  );
}
