import { useFetcher } from '@remix-run/react';
import { Loader2Icon, CheckIcon, CircleXIcon } from 'lucide-react';
import { If } from '~/components/If';
import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import { cn } from '~/lib/utils';

export function SubscribeForm() {
  const fetcher = useFetcher<{ success: boolean }>();
  const isLoading = fetcher.state !== 'idle';
  const submissionSuccess = fetcher.data?.success;
  const submissionError = fetcher.data?.success === false;

  return (
    <fetcher.Form method="POST" action="/api/subscribe">
      <div className="absolute left-1/2 top-0 mx-auto -mt-6 flex w-[70%] -translate-x-1/2 flex-row rounded-lg border border-[#2D1DB7]">
        <Input
          name="email"
          type="email"
          className="rounded-r-none border-none px-4 py-6 focus-visible:ring-inset focus-visible:ring-[#2D1DB7] focus-visible:ring-offset-0"
          placeholder="Enter your email..."
        />
        <Button
          className={cn('rounded-l-none rounded-r-lg py-6', { 'bg-red-600': submissionError })}
          type="submit"
          disabled={isLoading}
        >
          <Loader2Icon className={cn('mr-2 h-4 w-4 animate-spin', { hidden: !isLoading })} />
          <If condition={submissionSuccess}>
            <CheckIcon />
          </If>
          <If condition={!submissionSuccess && !submissionError}>Submit</If>
          <If condition={submissionError}>
            <CircleXIcon />
          </If>
        </Button>
      </div>
    </fetcher.Form>
  );
}
