import type { MetaFunction } from '@remix-run/cloudflare';
import { Link } from '@remix-run/react';
import asyLogoImgUrl from '~/assets/images/brand-logos/asy-logo.png';
import bahudhaFoundationLogoImgUrl from '~/assets/images/brand-logos/bahudha-foundation-logo.png';
import sadafalEducationLogoImgUrl from '~/assets/images/brand-logos/sadafal-education-logo.png';
import asyActivityIdy2024ImgUrl from '~/assets/images/homepage/asy-activities/idy-delhi-2024.png';
import asyActivityIdyMauImgUrl from '~/assets/images/homepage/asy-activities/idy-mau-2023.jpeg';
import asyActivityIncredibleIndiaImgUrl from '~/assets/images/homepage/asy-activities/paryatan-parv-2022.jpeg';
import kumbhGallery1ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery1.jpeg';
import kumbhGallery2ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery2.jpeg';
import kumbhGallery3ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery3.jpeg';
import kumbhGallery4ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery4.jpeg';
import kumbhGallery5ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery5.jpeg';
import kumbhGallery6ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery6.jpeg';
import kumbhGallery7ImgUrl from '~/assets/images/homepage/kumbh-gallery/gallery7.jpeg';
import teamMemberGangadharImgUrl from '~/assets/images/homepage/team-members/gangadhar.jpg';
import teamMemberGopalImgUrl from '~/assets/images/homepage/team-members/team-member-gopal.jpeg';
import teamMemberNaamDeoImgUrl from '~/assets/images/homepage/team-members/team-member-naamdeo.png';
import teamMemberRobinImgUrl from '~/assets/images/homepage/team-members/team-member-robin.png';
import naamdeoSignatureBlackImgUrl from '~/assets/images/naamdeo-signature-black.png';
import embraceKumbhTestimonialPreviewImgUrl from '~/assets/images/testimonials/embrace-kumbh.png';
import { BrandCard } from '~/components/brand-card';
import { CERTIFICATION_DATA, Certifications } from '~/components/certifications';
import { ContentSection } from '~/components/content-section';
import { ImageCarousel } from '~/components/image-carousel';
import { Button } from '~/components/ui/button';
import { Separator } from '~/components/ui/separator';
import { Typography, Emphasize, DashedText } from '~/components/ui/typography';
import { CF_IMAGE_URLS } from '~/lib/cf-image-urls';
import { getSeoMeta } from '~/lib/seo';
import { AsyBrandCard } from './asy-brand-card';
import { AsyFeatureCard } from './asy-feature-card';
import { ImageGrid } from './image-grid';
import { SocialServicesFeatureCard } from './social-services-feature-card';
import { SubscribeForm } from './subscribe-form';
import { SukritSocialRow } from './sukrit-social-row';
import { TeamFeatureCard } from './team-feature-card';
import { TestimonialGrid } from './testimonial-grid';
import { TestimonialSwitcher } from './testimonial-switcher';
import { VanityFeature } from './vanity-feature';

export const meta: MetaFunction = (args) => {
  return getSeoMeta(
    {
      title: 'Sukrit Wellness',
      description: 'Transformative Wellness Experiences',
    },
    args
  );
};

export default function Index() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="relative pt-12">
      <nav className="absolute top-3 flex w-full flex-row justify-center px-[2%] lg:top-12 lg:justify-between lg:px-[5%]">
        <Typography className="text-xs uppercase tracking-widest sm:text-sm">
          <Link to="#kumbh-mela">Kumbh Mela</Link> • <Link to="#service-partners">Service partners</Link> •{' '}
          <Link to="#our-team">Our Team</Link>
        </Typography>
        <SukritSocialRow className="hidden lg:flex" />
      </nav>
      <section>
        <div className="flex flex-col items-center">
          <img src={CF_IMAGE_URLS['sukritwellness-logo-blue']} alt="Sukrit Wellness" className="h-28 w-28" />
          <DashedText className="w-[90%] md:w-[80%]">
            <Typography variant="h1" className="mx-5 md:px-10">
              Sukrit Wellness
            </Typography>
          </DashedText>
          <Typography variant="tagline" className="mt-2 lg:text-sm">
            Transformative Wellness Experiences
          </Typography>
        </div>
        <div className="relative my-12 h-[50vh] md:h-[70vh]">
          <img className="absolute h-full w-full object-cover" src={CF_IMAGE_URLS['hero-cover']} alt="Kumbh 2025" />
          <div className="flex h-full w-full flex-col items-center justify-center p-4 text-center md:absolute md:bottom-16 md:left-40 md:h-auto md:w-auto md:items-start md:justify-start md:p-0 md:text-left [&>*]:z-10">
            <Typography variant="h1" as="h2" className="mb-2 font-fraunces font-bold leading-10 text-white">
              Kumbh 2025 beckons...
            </Typography>
            <Typography variant="h3" as="p" className="text-balance text-white">
              Join the largest congregation on Earth
              <br className="hidden md:block" />
              along with Sukrit Wellness Tours
            </Typography>
            <div className="mt-8 flex space-x-4">
              <div
                className=""
                onClick={() => {
                  document.getElementById('book-now')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                }}
              >
                <Button className="">Book Now</Button>
              </div>
              <Link to="#kumbh-mela" className="scroll-smooth">
                <Button className="" variant="secondary">
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
          <div className="absolute bottom-0 left-1/2 h-1 w-[80px] -translate-x-1/2 bg-primary md:h-2 md:w-[120px]"></div>
        </div>
      </section>
      <ContentSection className="flex flex-col items-center">
        <Typography variant="h3" as="p" className="text-pretty text-center">
          True wellness entails <Emphasize>deeply</Emphasize> transformational, <Emphasize>soul-lifting</Emphasize>{' '}
          experiences <br className="hidden md:block" /> catapulting us towards our <Emphasize> best selves</Emphasize>.
          At Sukrit Wellness we promise to <br className="hidden md:block" /> leave you physically, mentally, socially
          and spiritually <Emphasize> recharged</Emphasize>.
        </Typography>
        <Typography variant="mutedText" className="mt-8 text-pretty text-center text-xl">
          Sukrit Wellness encompasses a diverse range offerings, each dedicated to <br className="hidden md:block" />{' '}
          delivering specialized, holistic wellness solutions.
        </Typography>
      </ContentSection>
      <section className="my-12">
        <div>
          <Certifications certifications={CERTIFICATION_DATA} />
        </div>
      </section>
      <div className="mx-auto mb-14 mt-12 w-[90%] md:w-4/5">
        <Separator />
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="aspect-[16/13] w-full lg:w-2/5">
          <ImageCarousel
            imageUrls={[
              CF_IMAGE_URLS['founder-gallery-1'],
              CF_IMAGE_URLS['founder-gallery-2'],
              CF_IMAGE_URLS['founder-gallery-3'],
            ]}
          />
        </div>
        <div className="flex w-full items-center lg:w-1/2">
          <div className="rounded-lg bg-accent p-4 lg:p-20">
            <Typography variant="h3" as="p">
              ❝<br />
              No matter where we reach, the idea of ‘we’ as <Emphasize>‘who we are’</Emphasize> remains the key to our
              well-being.
            </Typography>
            <img src={naamdeoSignatureBlackImgUrl} alt="Naamdeo" className="mb-2 mt-8 w-28" />
            <Typography variant="tagline" className="font-normal">
              Founder, Sukrit Wellness Tours
            </Typography>
            <Separator className="my-8" />
            <div>
              <a href="https://www.naamdeo.org" className="text-blue-700">
                naamdeo.org
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Kumbh Section */}
      <div className="mb-12 mt-24" id="kumbh-mela">
        <ContentSection className="text-center">
          <Typography variant="h2">Kumbh Mela 2025</Typography>
          <Typography variant="h4" as="p">
            Sukrit Wellness is proud and excited to bring Kumbh Mela 2025 to you.
          </Typography>
        </ContentSection>
        <div className="mt-8 flex flex-col justify-center gap-12 px-4 lg:flex-row lg:px-0">
          <BrandCard
            className="lg:w-[40%]"
            logoImgUrl={CF_IMAGE_URLS['kumbh-sukrit-logo-black']}
            backgroundImgUrl={CF_IMAGE_URLS['kumbh-sukrit-brand-card']}
            title="Kumbh Sukrit"
            tagline="Experience Kumbh @ Sanskriti Van"
            description="Premium thatch huts and mudhouses for an exclusive experience of Kumbh. Available now, upon invitation."
            learnMoreUrl="https://kumbhsukrit.com/"
            websiteUrl="https://kumbhsukrit.com/"
            facebookUrl="https://www.facebook.com/kumbhsukrit"
            instagramUrl="https://www.instagram.com/kumbhsukrit/"
          />
          <BrandCard
            className="lg:w-[40%]"
            logoImgUrl={CF_IMAGE_URLS['camp67-logo-white']}
            backgroundImgUrl={CF_IMAGE_URLS['camp-67-brand-card']}
            title="Camp67"
            tagline="Kumbh for one and all"
            description="Budget-friendly tent dormitory offerings for the solo traveller, friends, and families. Book online now."
            learnMoreUrl="https://camp67.com/"
            websiteUrl="https://camp67.com/"
            facebookUrl="https://www.facebook.com/camp67"
            instagramUrl="https://www.instagram.com/camp67/"
          />
        </div>
        <ContentSection className="mt-20 text-center">
          <Typography variant="h4" as="p" className="font-sans">
            We have been hosting Kumbh for <strong>over a decade</strong>, with a highly specialized team
          </Typography>
        </ContentSection>
        <div
          className="mt-8 flex flex-col justify-center gap-8 py-20 text-center md:flex-row"
          style={{ background: 'linear-gradient(90deg, #CFFFEB 0%, #B2FFDF 100%)' }}
        >
          <VanityFeature
            title="10,000+"
            tagline="Lives Impacted"
            description="Attendees of Kumbh offerings hosted by us over the last decade."
          />
          <VanityFeature
            title="24+"
            tagline="Countries"
            description="Where Kumbh-goers have arrived at our offerings from."
          />
          <VanityFeature
            title="15,000+"
            tagline="Follows"
            description="Across Facebook, Instagram, YouTube, and more."
          />
        </div>
        <div className="px-4 py-14 lg:px-0">
          <TestimonialSwitcher
            testimonials={[
              {
                embedUrl: 'https://www.youtube.com/embed/i8LXte3EFPg?si=DCq9T-RD_JbH0u5V',
                previewImgUrl: CF_IMAGE_URLS['ks-testimonial-valarie-thumbnail'],
                title: 'I’m excited to come back... • 3m 49',
                description: 'Hear from Valerie, who joined us in Kumbh 2019.',
              },
              {
                embedUrl: 'https://www.youtube.com/embed/XUIs_7__LKY?si=4LTokDxcr5WwAHi1',
                previewImgUrl:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/c9b4e46d-e6b6-45a5-b2b2-60d64b9b2a00/public',
                title: 'Looking back on Kumbh... • 7m 18s',
                description: 'A montage of our guests from over the years.',
              },
              {
                embedUrl: 'https://www.youtube.com/embed/9zBCiOi9mD8?si=kX45NjiXcCDs43pt',
                previewImgUrl: embraceKumbhTestimonialPreviewImgUrl,
                title: 'Kumbh: Embrace, move forward • 1m 17s',
                description: 'The spirit of Kumbh, described by Naam Deo.',
              },
              {
                embedUrl: 'https://www.youtube.com/embed/1EATgDuQ0io?si=quk0f7h05gZzEhS1',
                previewImgUrl: 'https://i3.ytimg.com/vi/1EATgDuQ0io/maxresdefault.jpg',
                title: 'Sacredness and Purity of Kumbh • 2m 37s',
                description: 'Reflect on the relevance of Kumbh with Naam Deo',
              },
            ]}
          />
        </div>
        <ContentSection>
          <TestimonialGrid
            testimonials={[
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/966e715a-16e2-477d-1d5c-f9f406c99b00/public',
                imageAlt: 'Amit and Swapna Pawar of Microsoft and Edukinect at our campsite in 2019.',
                quote:
                  "We didn't come here with any expectations in particular; we found it to be extremely beautiful, very comfortable. The service from everybody has been very good. We felt extremely at home, and will miss this place. We learnt many things while we were here.",
                author: {
                  name: 'Amit & Swapna Pawar',
                  location: 'Microsoft & Edukinect',
                  videoSrc: 'https://www.youtube.com/embed/SeRjwcdlVFQ?si=XrmtphSJorb5A60d',
                },
              },
              {
                imageSrc:
                  '	https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/7ff2bd53-aacc-4472-2091-c5ef8c31d000/public',
                imageAlt: 'Richard Singh from New York USA, at our campsite in 2019.',
                quote:
                  'The camp is fantastic. The staff was incredibly amazing and very accommodating in every possible way. Everyone became my friend here. I’m going to miss this place, and everybody here. It has a wonderful energy; I hope to take a little bit back with me, home.',
                author: {
                  name: 'Richard Singh',
                  location: 'New York',
                  videoSrc: 'https://www.youtube.com/embed/wqfyTic9L7E?si=oasCEFFQhDGV-2Kh',
                },
              },
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/8699fcd3-8b83-47da-a1ed-e61cce9a2000/public',
                imageAlt: 'Senior VP of Purchasing at Volvo, Rajesh Mittal at our campsite in 2019.',
                quote:
                  'We came here to experience the Kumbh Mela. We never imagined we would have such a beautiful place where we can have such a peaceful and holy experience. We are going back to happy. We really enjoyed our stay and we’d also like to come back in the future, thank you.',
                author: {
                  name: 'Rajesh Mittal',
                  location: 'Senior VP of Purchasing, Volvo',
                  videoSrc: 'https://www.youtube.com/embed/5tSohpCNSJ8?si=i6aZ3A-FZ2Qu2kio',
                },
              },
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/b194211e-043f-41c4-53c8-3ea94cc9c300/public',
                imageAlt: 'Gabriella Burnel, Sanskrit Scholar at our campsite in 2019.',
                quote:
                  'One thing I learnt in this place is: there is no need to wish for anything. It will happen in its natural flow, as soon as you let it go. That’s how I came to learn meditation here. You learn that you can simply watch as everything unfolds around you.',
                author: {
                  name: 'Gabriella Burnel',
                  location: 'Sanskrit Scholar',
                  videoSrc: 'https://www.youtube.com/embed/LygBGodZrk4?si=LcchA0Zg_w88lmH9',
                },
              },
            ]}
          />
        </ContentSection>
        <div className="mx-auto my-12 w-[90%] md:w-4/5">
          <Separator />
        </div>
        <div>
          <ImageGrid
            columns={[
              [
                { src: kumbhGallery1ImgUrl, alt: 'Kumbh Gallery 1', className: '' },
                { src: kumbhGallery4ImgUrl, alt: 'Kumbh Gallery 4', className: '' },
              ],
              [
                { src: kumbhGallery2ImgUrl, alt: 'Kumbh Gallery 2', className: '' },
                { src: kumbhGallery5ImgUrl, alt: 'Kumbh Gallery 5', className: '' },
                { src: kumbhGallery7ImgUrl, alt: 'Kumbh Gallery 7', className: '' },
              ],
              [
                { src: kumbhGallery3ImgUrl, alt: 'Kumbh Gallery 3', className: '' },
                { src: kumbhGallery6ImgUrl, alt: 'Kumbh Gallery 6', className: '' },
                {
                  src: 'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/6ed2b97c-08b5-4f43-d2e4-664f82508a00/public',
                  alt: 'Kumbh Gallery 8',
                  className: '',
                },
              ],
            ]}
          />
          <ContentSection
            className="mt-12 flex flex-col items-center justify-center gap-4 md:gap-8 lg:flex-row"
            id="book-now"
          >
            <Button className="w-full px-4 py-10 md:w-auto md:p-10" asChild>
              <a href="https://kumbhsukrit.com/" target="_blank" rel="noreferrer">
                <div className="flex flex-col items-start justify-center text-center">
                  <Typography as="span" className="w-full font-fraunces font-extrabold underline">
                    Visit Kumbh Sukrit {' >'}
                  </Typography>
                  <Typography variant="p" as="span" className="w-full text-wrap text-base normal-case tracking-normal">
                    View our premium mudhouse offerings now!
                  </Typography>
                </div>
              </a>
            </Button>
            <Button variant="secondary" className="w-full px-4 py-10 md:w-auto md:p-10" asChild>
              <a href="https://camp67.com/" target="_blank" rel="noreferrer">
                <div className="flex flex-col items-start justify-center text-center">
                  <Typography as="span" className="w-full font-fraunces font-extrabold underline">
                    Visit Camp67 {' >'}
                  </Typography>
                  <Typography variant="p" as="span" className="w-full text-wrap text-base normal-case tracking-normal">
                    Book a tent dormitory offering online now!
                  </Typography>
                </div>
              </a>
            </Button>
          </ContentSection>
        </div>
      </div>
      {/* ASY Section */}
      <div className="bg-accent py-16 text-accent-foreground" id="service-partners">
        <ContentSection className="flex flex-col items-center text-center">
          <Typography variant="h2">Special Service Partner: Abhyas School of Yoga</Typography>
          <Typography variant="h4" as="p" className="mt-4 max-w-3xl text-pretty">
            Sukrit Wellness is proudly supported by Abhyas School of Yoga in providing Yoga, Yajna, and other Ancient
            Vedic rituals to all guests at Sukrit Wellness locations.
          </Typography>
        </ContentSection>
        <div className="my-12 flex justify-center">
          <AsyBrandCard />
        </div>
        <ContentSection className="mb-20 mt-24">
          <TestimonialGrid
            testimonials={[
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/8a4124f4-afaf-443f-bf47-64c5cd0fc500/public',
                imageAlt: 'Sasha from Russia at Abhyas School of Yoga.',
                quote:
                  'The hospitality at this centre is truly exceptional. Every person here takes genuine care of my mom and me. Their willingness to help is sincere, and their actions are driven by true intention. I am filled with gratitude for what this place has done for my mom. Thank you immensely.',
                author: {
                  name: 'Sasha',
                  location: 'Russia',
                  videoSrc: 'https://www.youtube.com/embed/5tAELJmuiqY?si=Yugh0zFJGZOHnsCo',
                },
              },
              {
                imageSrc:
                  'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/33f70287-e512-4d98-9608-ab459bf51800/public',
                imageAlt: 'Gunjan Saini Bradshaw, Miss India Winner, 2012 at Abhyas School of Yoga.',
                quote:
                  "This place feels like a vacation, far better than just sleeping in and indulging in unhealthy food while sightseeing. I genuinely love everything about it—the place, the people, and the overall experience. Words can't fully capture the joy on my face, but I hope you can sense it and decide for yourself.",
                author: {
                  name: 'Gunjan Saini Bradshaw',
                  location: 'Miss India Winner, 2012',
                  videoSrc: 'https://www.youtube.com/embed/HRgHRCl9Sho?si=E3FytXIvFMnF8_Au',
                },
              },
            ]}
          />
        </ContentSection>
        <div className="mx-auto mb-24 w-4/5">
          <div className="flex w-full flex-col justify-center gap-6 md:flex-row">
            <AsyFeatureCard
              title="International Yoga Day, 2024"
              description="In collaboration with Ministry of Tourism, India"
              imageSrc={asyActivityIdy2024ImgUrl}
            />
            <AsyFeatureCard
              title="International Yoga Day in Mauritius"
              description="In collaboration with High Commission of India"
              imageSrc={asyActivityIdyMauImgUrl}
            />
            <AsyFeatureCard
              title="Paryatan Parv, 2022"
              description="In collaboration with Incredible India"
              imageSrc={asyActivityIncredibleIndiaImgUrl}
            />
          </div>
        </div>
        <ContentSection className="flex flex-col items-center text-center">
          <Typography variant="h2">Our social service partners</Typography>
          <Typography variant="h4" as="p" className="mt-4 max-w-3xl text-pretty">
            At Sukrit Wellness, we offer the opportunity to <strong>serve</strong> to all attendees at all locations,
            ranging from environmental impact to women empowerment and more.
          </Typography>
        </ContentSection>
        <div className="mx-auto mb-12 w-4/5">
          <div className="flex w-full flex-col justify-center gap-6 md:flex-row">
            <SocialServicesFeatureCard
              title="Abhyas School of Yoga"
              description="Environmental care and activities"
              imageSrc={asyLogoImgUrl}
              websiteUrl="https://www.abhyasschoolofyoga.org/"
            />
            <SocialServicesFeatureCard
              title="Sadafal Education"
              description="Vocational education and development"
              imageSrc={sadafalEducationLogoImgUrl}
              websiteUrl="https://sadafal-education.com/"
            />
            <SocialServicesFeatureCard
              title="Bahudha Foundation"
              description="Global peace and harmony"
              imageSrc={bahudhaFoundationLogoImgUrl}
              websiteUrl="https://bahudha-foundation.org/"
            />
          </div>
        </div>
      </div>
      {/* Our Team Section */}
      <div className="pt-16" id="our-team">
        <ContentSection className="mb-8 flex flex-col items-center text-center">
          <Typography variant="h2">Our Team</Typography>
          <Typography variant="h4" as="p" className="mt-4 max-w-3xl text-pretty">
            A passionate, enthusiastic group of individuals, striving to give you the best.
          </Typography>
        </ContentSection>
        <div className="mx-auto md:px-4 lg:w-4/5 lg:px-0 xl:w-3/5">
          <div className="grid gap-8 md:grid-cols-2">
            <TeamFeatureCard
              title="Naam Deo"
              tagline="Founder"
              description='A true visionary and ambassador of global change and transformation, Naam Deo illuminates the statement "be the change you seek in the world" not only through his actions, but in others lives.'
              imageSrc={teamMemberNaamDeoImgUrl}
            />
            <TeamFeatureCard
              title="Gangadhar M. Chilka"
              tagline="Director, Business Development"
              description="With over 36 years of experience in tourism industry and as the Vice President of the Global Toursim Board, Gangadhar M. ChilkaGangadhar M. Chilka brings unparalleled expertise and leadership to Sukrit Wellness Tours."
              imageSrc={teamMemberGangadharImgUrl}
              imageClassName="object-top"
            />
            <TeamFeatureCard
              title="Robin Rajan"
              tagline="Director, Events & Hospitality"
              description="Robin Rajan is a well accomplished professional renowned for his extensive operational knowledge, experience of the hospitality industry and his enthusiastic management style."
              imageSrc={teamMemberRobinImgUrl}
            />
            <TeamFeatureCard
              title="Gopal Rishi"
              tagline="Manager, Planning & Strategy"
              description="Gopal Rishi holds decades of experience working in big corporations, where his ability to mobilise teams in pursuit of a common goal and mission has left hundreds inspired and enthused."
              imageSrc={teamMemberGopalImgUrl}
            />
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="mt-16">
        <div className="mb-12 flex flex-col items-center text-center">
          <Typography variant="h2">Get in touch</Typography>
          <Typography variant="h5" as="p" className="mt-1 max-w-3xl text-pretty">
            Have any inquiries? Chat to us.
          </Typography>
        </div>
        <div className="relative bg-[#EDEBFF] pb-10 pt-28">
          <SubscribeForm />
          <div className="mx-auto flex w-[60%] flex-col gap-2">
            <div className="flex flex-col justify-between gap-2 md:flex-row">
              <Typography variant="tagline">Transformative Wellness Experiences</Typography>
              <SukritSocialRow />
            </div>
            <div className="">
              <Typography variant="h4" as="p" className="text-base">
                info@sukritwellness.com / +918860092457
              </Typography>
            </div>
            <Separator className="my-6 border-solid" />
            <div>
              <Typography variant="mutedText" className="text-sm">
                All Rights Reserved {currentYear}, Sukrit Wellness ©
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
