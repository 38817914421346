import { Typography } from '~/components/ui/typography';

export function SocialServicesFeatureCard({
  title,
  description,
  imageSrc,
  websiteUrl,
}: {
  title: string;
  description: string;
  imageSrc: string;
  websiteUrl: string;
}) {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <img src={imageSrc} alt={title} className="aspect-[16/11] w-48 rounded-lg object-contain" />
      <Typography variant="h4" as="p" className="mt-4 text-center">
        {title}
      </Typography>
      <Typography variant="mutedText" className="text-center">
        {description}
      </Typography>
      <a href={websiteUrl} target="_blank" rel="noreferrer" className="mt-2 block text-center underline">
        Visit Website
      </a>
    </div>
  );
}
