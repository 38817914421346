import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { CF_IMAGE_URLS } from '~/lib/cf-image-urls';

export const CERTIFICATION_DATA = [
  {
    imageUrl: CF_IMAGE_URLS['certifications-ministry-ayush'],
    imageAlt: 'Ministry of Ayush',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-ficci'],
    imageAlt: 'Federation of Indian Chambers of Commerce & Industry',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-inbound-tour-operator'],
    imageAlt: 'Inbound Tour Operator',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-incredible-india'],
    imageAlt: 'Incredible India',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-iatp'],
    imageAlt: 'Indian Association of Tour Operators, India',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-ministry-tourism'],
    imageAlt: 'Ministry of Tourism, Government of India',
  },
  {
    imageUrl: CF_IMAGE_URLS['certifications-yoga-board'],
    imageAlt: 'Yoga Certification Board, Ministry of Ayush',
  },
];

export function Certifications({ certifications }: { certifications: { imageUrl: string; imageAlt: string }[] }) {
  return (
    <ul className="flex list-none flex-wrap items-center justify-center gap-8 md:flex md:flex-row">
      {certifications.map(({ imageUrl, imageAlt }) => (
        <li key={imageUrl} className="flex flex-col">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <img src={imageUrl} alt={imageAlt} className="h-12" />
              </TooltipTrigger>
              <TooltipContent>{imageAlt}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </li>
      ))}
    </ul>
  );
}
