export const CF_IMAGE_URLS = {
  'sukritwellness-logo-blue':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/0ae32acb-b18e-4da2-0b24-7081859b6f00/public',
  'certifications-ministry-ayush':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/70b44f09-ad9f-40ea-be88-f781a34c4400/public',
  'certifications-ficci':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/f333d855-7567-4cd8-e957-7bdd9bc77800/public',
  'certifications-inbound-tour-operator':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/59c414fb-203a-4f51-cc97-6b127549c800/public',
  'certifications-incredible-india':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/8821daa2-06bf-4581-0984-ff5e4a492a00/public',
  'certifications-iatp': 'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/9930b5bd-b311-4897-0f81-7694fc4d6400/public',
  'certifications-ministry-tourism':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/31025c91-2058-4976-fee1-95435109cc00/public',
  'certifications-yoga-board':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/98c5ffce-0872-4c8c-b305-4e2807323800/public',
  'hero-cover': 'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/2392a72e-b36a-4d6a-20e4-7307c8756b00/public',
  'founder-gallery-1': 'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/c0ca1c79-5497-4792-cd58-145997b0ea00/public',
  'founder-gallery-2': 'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/24f4f757-32a1-4a7e-e448-f8ead3991500/public',
  'founder-gallery-3': 'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/b259fb43-ee3f-44e6-80e6-ba363b01bb00/public',
  'kumbh-sukrit-logo-black':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/b382adad-6469-4266-82c1-49c512387100/public',
  'kumbh-sukrit-brand-card':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/86068fee-5ac5-490a-91ad-37f38ef1ce00/public',
  'ks-testimonial-valarie-thumbnail':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/27bd91ea-7bdd-4aa8-ad05-ec9a0faa6b00/public',
  'camp67-logo-white': 'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/3fc3405b-8c7e-4030-6436-6646a245ea00/public',
  'camp-67-brand-card': 'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/94c804aa-29cd-4c12-d1cb-6ac381e25400/public',
};
