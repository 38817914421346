import { Typography } from '~/components/ui/typography';
import { cn } from '~/lib/utils';

export function TeamFeatureCard({
  title,
  tagline,
  description,
  imageSrc,
  imageClassName,
}: {
  title: string;
  tagline: string;
  description: string;
  imageSrc: string;
  imageClassName?: string;
}) {
  return (
    <div className="flex flex-1 flex-col items-stretch justify-stretch rounded-b-lg bg-accent pb-4 text-accent-foreground">
      <img src={imageSrc} alt={title} className={cn('aspect-[4/3] object-cover', imageClassName)} />
      <div className="">
        <Typography variant="h4" as="p" className="mt-4 text-center font-bold">
          {title}
        </Typography>
        <Typography variant="tagline" className="mx-auto mt-1 w-[80%] text-center">
          {tagline}
        </Typography>
        <Typography className="mx-auto mt-2 w-[80%] text-balance">{description}</Typography>
      </div>
    </div>
  );
}
