import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '~/components/ui/typography';
import { YouTubeInlinePlayer } from '~/components/ui/youtube-inline-player';
import { cn } from '~/lib/utils';

interface Testimonial {
  embedUrl: string;
  previewImgUrl: string;
  title: string;
  description: string;
}

interface TestimonialSwitcherProps {
  testimonials: Testimonial[];
}

export function TestimonialSwitcher({ testimonials }: TestimonialSwitcherProps) {
  const [selectedTestimonial, setSelectedTestimonial] = useState(0);
  let timer: NodeJS.Timeout | undefined = undefined;
  useEffect(() => {
    timer = setInterval(() => {
      setSelectedTestimonial((prevTestimonial) => (prevTestimonial + 1) % testimonials.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const handleTestimonialClick = useCallback(
    (index: number) => {
      setSelectedTestimonial(index);
      clearInterval(timer);
    },
    [timer]
  );

  const handleOnPlay = useCallback(() => {
    clearInterval(timer);
  }, [timer]);

  return (
    <div className="flex flex-col items-center justify-center lg:flex-row">
      <div className="aspect-[16/10] w-full lg:w-[40%]">
        <YouTubeInlinePlayer
          embedUrl={testimonials[selectedTestimonial].embedUrl}
          previewImgUrl={testimonials[selectedTestimonial].previewImgUrl}
          onPlay={handleOnPlay}
        >
          <div className="px-2 text-center">
            <Typography variant="h4" as="p" className="font-bold">
              {testimonials[selectedTestimonial].title}
            </Typography>
            <Typography className="font-light">{testimonials[selectedTestimonial].description}</Typography>
          </div>
        </YouTubeInlinePlayer>
      </div>
      <div className="flex w-full items-center lg:aspect-[16/10] lg:w-[40%]">
        <div className="w-full">
          {testimonials.map((testimonial, index) => (
            <div
              onClick={handleTestimonialClick.bind(null, index)}
              className={cn(
                'cursor-pointer rounded-r-lg p-2 text-primary-foreground transition lg:p-4',
                { 'border-b hover:bg-primary/50 hover:text-primary-foreground': index !== selectedTestimonial },
                { 'bg-primary': index === selectedTestimonial }
              )}
              key={testimonial.embedUrl + index}
            >
              <Typography variant="h4" as="p" className="font-bold">
                {testimonial.title}
              </Typography>
              <Typography variant="mutedText">{testimonial.description}</Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
