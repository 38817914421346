import { MetaArgs, MetaDescriptor } from '@remix-run/cloudflare';

export interface SeoInput {
  title: string;
  description?: string;
  keywords?: string;
  previewImageSrc?: string;
  previewImageAlt?: string;
}

export function getSeoMeta(
  { title, description, keywords, previewImageSrc, previewImageAlt }: SeoInput,
  args: MetaArgs,
  extraMeta?: MetaDescriptor[]
): MetaDescriptor[] {
  if (!previewImageSrc) {
    previewImageSrc = 'https://public-assets.sukritwellness.com/images/logo.png';
  }
  if (!previewImageAlt) {
    previewImageAlt = 'Sukrit Wellness';
  }
  if (!description) {
    description = 'Sukrit Wellness';
  }
  if (!keywords) {
    keywords = 'Sukrit Wellness';
  }

  const baseMeta: MetaDescriptor[] = [
    { title: `${title}` },
    { name: 'description', content: description },
    { name: 'keywords', content: keywords },
    {
      tagName: 'link',
      rel: 'canonical',
      href: new URL(args.location.pathname, 'https://beta.sukritwellness.com/').toString(),
    },
    { name: 'twitter:url', content: 'https://www.sukritwellness.com/' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { property: 'og:site_name', content: 'Sukrit Wellness' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.sukritwellness.com/' },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
  ];

  const previewImageMeta: MetaDescriptor[] = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: previewImageSrc },
    { name: 'twitter:image:alt', content: previewImageAlt },
    { property: 'og:image', content: previewImageSrc },
    { property: 'og:image:alt', content: previewImageAlt },
    { property: 'og:image:url', content: previewImageSrc },
    { property: 'og:image:secure_url', content: previewImageSrc },
  ];

  return [...baseMeta, ...previewImageMeta, ...(extraMeta ?? [])];
}
